<template>
  <div class="box"> 
    <div id="downtown-model" class='model-box'>
      <section id="loading-screen">
        <div id="loader"></div>
      </section>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

  // models
let modelContent = null;
let scene=  null;
let camera = null;
let renderer = null;
let controls = null;

export default {
  data(){
    return {
      camera: null
    }
  },
  mounted() {
    this.initThree()
  },
  methods: {
    onTransitionEnd(event) {
      const element = event.target
      element.remove()
    },
    cleanupModels(){
      if(!scene) return
      scene.remove(modelContent)
      modelContent.traverse((node) => {
        if(!node.isMesh) return
        node.geometry.dispose()
        node.material.dispose()
      })
      scene.remove(modelContent);
      const el = document.getElementById("downtown-model")
      if(el) el.remove()
      modelContent = null;
      scene=  null;
      camera = null;
      renderer = null;
      controls = null;
    },
    animate() {
      controls.update()
      renderer.render(scene, camera)
      requestAnimationFrame(this.animate)
    },
    initThree() {
      scene = new THREE.Scene()

      const container = document.querySelector('.model-box')
      const canvas = document.createElement('canvas')
      canvas.setAttribute("id", "downtown-model")
      canvas.classList.add('modelContainer')
      canvas.style.width = "860px"
      canvas.style.height = "820px"
      container.appendChild(canvas)
      // const canvas = document.querySelector('#downtown-model')
      renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
      renderer.shadowMap.enabled = true
      renderer.physicallyCorrectLights = true
      renderer.outputEncoding = THREE.sRGBEncoding
      renderer.setClearColor( 0xcccccc )
      renderer.setPixelRatio( window.devicePixelRatio )
      renderer.setSize( canvas.clientWidth, canvas.clientHeight )

      camera = new THREE.PerspectiveCamera(
        60,
        canvas.clientWidth / canvas.clientHeight,
        0.1,
        1000
      )
      camera.position.z = 10
      scene.add( camera );

      const loadingManager = new THREE.LoadingManager(() => {
        const loadingScreen = document.getElementById( 'loading-screen' )
        loadingScreen.classList.add( 'fade-out' )
        loadingScreen.addEventListener( 'transitionend', this.onTransitionEnd )
      })

      var pmremGenerator = new THREE.PMREMGenerator( renderer )
      pmremGenerator.compileEquirectangularShader()
      new RGBELoader()
        .setDataType( THREE.UnsignedByteType )
        .setPath( 'https://cdn.toureyes.cn/models/' )
        .load( "024.hdr", function ( texture ) {
          var envMap = pmremGenerator.fromEquirectangular( texture ).texture
          scene.background = envMap
          // scene.environment = envMap
          texture.dispose()
          pmremGenerator.dispose()
      })

      const loader = new GLTFLoader(loadingManager).setCrossOrigin('anonymous')

      loader.load('https://cdn.toureyes.cn/models/QingTian_v2.glb', (gltf) => {
        const object = gltf.scene
        modelContent = object
        object.traverse((o) => {
          if (o.isMesh) {
            o.castShadow = true
            o.receiveShadow = true
          }
        })

        const box = new THREE.Box3().setFromObject(object)
        const size = box.getSize(new THREE.Vector3()).length()
        const center = box.getCenter(new THREE.Vector3())

        object.position.x += (object.position.x - center.x)
        object.position.y += (object.position.y - center.y)
        object.position.z += (object.position.z - center.z)
        controls.maxDistance = size * 10
        camera.near = size / 100
        camera.far = size * 100
        camera.fov = 9
        camera.updateProjectionMatrix()

        camera.position.copy(new THREE.Vector3(-2,0.6,-1.8))
        // this.camera.position.x += size / 2.0
        // this.camera.position.y += size / 5.0
        // this.camera.position.z += size / 2.0
        camera.lookAt(center)
        scene.add(object)
      })

      controls = new OrbitControls(camera, renderer.domElement)
      controls.autoRotate = true
      controls.autoRotateSpeed = -1
      controls.screenSpacePanning = true
      controls.enabled = true

      const light1  = new THREE.AmbientLight(0xFFFFFF, 0.3)
      light1.name = 'ambient_light'
      camera.add( light1 )

      const light2  = new THREE.DirectionalLight(0xFFFFFF, 0.8 * Math.PI)
      light2.position.set(0.5, 0, 0.866) // ~60º
      light2.name = 'main_light'
      camera.add( light2 )

      this.animate()
    }
  }
}
</script>

<style lang="scss" scoped>
.box{
  position: relative;
  width: 100%;
  height: 100%;
}
.city-mode-box{
  position: absolute;
  top: 102px;
  left: -33px;
}

.model-box{
  position: absolute;
  top: 0px;
  left: 0px;
}

#loading-screen {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 1;
  transition: 1s opacity;
}

#loading-screen.fade-out {
  opacity: 0;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #9370DB;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #BA55D3;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #FF00FF;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>